.container {
    min-height: 0;
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-y: scroll;
}

.gridList {
    height: 100%;
    width: 100%;
}

.item {

}

.img {
    height: 400px;
    max-width: 100%;
}