.container {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    min-height: 0;
}

.left {
    flex-direction: column;
    display: flex;
    overflow-y: scroll;
}

.right {
    flex-direction: column;
    display: flex;
    flex: 1;
    min-height: 0;
}

.item {
    flex: 1;
}
