.row {
    display: flex;
    flex-direction: row;
    min-width: 0;
}

.column {
    display: flex;
    flex-direction: column;
    min-height: 0;
    flex: 1;
}

.space-around {
    justify-content: space-around;
}

.container {
    display: flex;
    flex-direction: column;
    min-height: 0;
    flex: 1;
    justify-content: space-around;
}

.img_container {
    min-height: 0;
}

.img {
    max-width: 100%;
    align-self: center;
}

.footer {
    background-color: lightgray;
    padding: 8px;
    text-align: center;
}
