.container {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
}

.item {
    padding: 8px;
}
