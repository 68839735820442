.app {
    text-size-adjust: none;
    /*font-family: helvetica, arial, sans-serif;*/
    line-height: 200%;
    display: flex;
    flex-direction: column;
    min-height: 0;
    height: 100%;
}

.headline {
    font-size: 24px;
    padding-top: 16px;
    margin-bottom: 12px;
    font-weight: 400;
}
